<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-detaching-wrap"
  >
    <div class="w-detaching-main">
      <div class="w-crumbs-href">
        <div
          v-if="hasPermission"
          class="edit-map-building-select w-input-sty"
        >
          <el-select
            popper-class="w-block-select-down"
            v-model="buildingId"
            @change="changebuilding"
            placeholder="请选择"
          >
            <el-option
              v-for="item in buildingList"
              :key="item.buildingCode"
              :label="item.buildingName"
              :value="item.buildingCode"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        class="iframe-content"
        v-if="hasPermission"
      >
        <iframe
          id="map"
          :src="iframeUrl"
          frameborder="0"
          width="100%"
          height="761px"
          ref="childrenIframe"
        ></iframe>
      </div>
      <div
        class="simpleInfoPanel"
        v-else
      >
        <div class="jurisdiction-wrap">
          <img
            src="~@/assets/images/Jurisdiction.png"
            alt=""
          >
          <p>暂无权限访问此页，如需开通权限，</p>
          <p>请联系管理员开通</p>
        </div>
      </div>
      <Footer v-if="!hasPermission"></Footer>
    </div>
  </div>
</template>

<script>
import { getCookie } from '@/utils'
import { mapGetters } from 'vuex'
import Footer from '@/components/Footer.vue'
import api from '@/api'
export default {
  data () {
    return {
      loading: true,
      projectList: [],
      userAuthorities: [],
      hasPermission: true,
      hasPermissionOpenclose: true,
      hasPermissionEdit: true,
      iframeUrl: '',
      buildingId: '',
      buildingList: [],
      childrenIframe: null
    }
  },
  components: {
    Footer
  },
  props: {
    projectId: {
      default: 0,
      type: Number
    },
    projectName: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    projectId () {
      this.getGdBuildingFunc()
    }
  },
  mounted () {
    const iframe = document.getElementById('map')
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', () => {
        // iframe加载完成后要进行的操作
        this.loading = false
      })
    } else {
      iframe.onload = () => {
        // iframe加载完成后要进行的操作
        this.loading = false
      }
    }
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 212
    })
    if (this.userAuthorities.length) {
      this.hasPermission = Boolean(this.userAuthorities[0].func)
      this.hasPermissionOpenclose = this.userAuthorities[0].func.indexOf('openclose') !== -1
      this.hasPermissionEdit = this.userAuthorities[0].func.indexOf('edit') !== -1
      if (this.projectId) {
        this.getGdBuildingFunc()
      }
    } else {
      this.hasPermission = false
      this.loading = false
    }
  },
  methods: {
    changebuilding () {
      this.reloadMap()
    },
    getGdBuildingFunc () {
      this.axios.post(api.getGdbuilding, { projectId: this.projectId })
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            this.buildingId = res.data.data[0].buildingCode
          }
          this.buildingList = res.data.data
          this.reloadMap()
          this.loading = false
        })
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.projectId
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    reloadMap () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://localhost:8001'
      } else if (window.location.href.indexOf('http://') > -1) {
        temUrl = 'http://' + document.domain + '/map'
      } else {
        temUrl = 'https://' + document.domain + '/map'
      }
      this.iframeUrl = temUrl + '/editMap.html?token=' +
        getCookie('wisdom_token') +
        '&projectName=' + this.projectName +
        '&projectId=' + this.projectId +
        '&buildingId=' + this.buildingId +
        '&taskId=11&type=0'
      this.childrenIframe = this.$refs.childrenIframe.contentWindow
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.iframe-content
  // position relative
.w-detaching-wrap
  // padding-bottom 16px
  .w-crumbs-href
    position relative
    margin-left 43px
    .edit-map-building-select
      margin-left 2px
  .simpleInfoPanel, .footer-wrap
    margin-left 204px
    width calc(100% - 226px)
  .footer-wrap
    margin-top 33px
.d-project-list
  float right
  margin-right 500px
</style>
